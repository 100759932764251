import { MainLayout } from 'components/MainLayout';
import { Seo } from 'components/Seo';

const Component = () => (
  <>
    <Seo title="404 - Page Not Found" />
    <MainLayout>404 - Page Not Found</MainLayout>
  </>
);

export default Component;
